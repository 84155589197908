import React from "react"
import Layout from "../components/layout"

export default function Receipt() {

  return(
    <Layout>
      <main className="container mx-auto px-6">
        <div className="max-w-prose">
          <h1 className="my-3 text-3xl font-semibold text-gray-700">Takk for foresørselen din</h1>
          <p className="text-gray-400">
            Vi tar kontakt med deg så snart vi kan.
          </p>
        </div>
      </main>
    </Layout>
  )
}