import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import MenuItems from "./menu_items"
import classNames from "classnames"

const ListLink = props => (
  <Link to={props.to}  className="block mt-4 lg:inline-block hover:text-gray-700 lg:mt-0 mr-10">{props.children}</Link>
)

export default function Menu({ data , style }) {

  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <>
      <button onClick={() => toggleExpansion(!isExpanded)} className="md:text-lg uppercase underline font-sans z-20" style={ style }>Meny</button>
      {/* <div className="absolute w-full h-full">
        <div className={ classNames( isExpanded ? 'z-30' : 'hidden', 'border-solid absolute w-full backdrop-filter backdrop-blur' ) } />
        <div className={ classNames( isExpanded ? `opacity-90 z-30` : `opacity-0`, `absolute w-full transform duration-300 bg-gray-900 text-gray-400 text-sm xl:text-base shadow-3xl p-12` )}>
          <div onClick={() => toggleExpansion(false)} className="absolute top-6 right-6 text-2xl font-sans font-bold cursor-pointer">X</div>
          <MenuItems data={data} className={`${ isExpanded ? `` : `hidden` }`} />
        </div>
      </div> */}
    </>
  )

}
