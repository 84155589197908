import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"

import Navigation from "./navigation"
import Footer from "./footer"

export default function Layout({ children }) {

  const [scale, setScale] = useState(1)
  const [padding, setPadding] = useState(4)
  const [shadow, setShadow] = useState(false)
  
  useEffect( () => {

    const handler = () => {
      setPadding( () => {
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
          // (window.innerWidth < 768) ? 1.5 : 1
          return 1
        } else if (document.body.scrollTop < 0 || document.documentElement.scrollTop < 0) {
          return (window.innerWidth < 768) ? 3 : 4
        } else {
          const padding = 4 - ( document.documentElement.scrollTop * 3 / 100 )
          return (window.innerWidth < 768) ? padding / 2 + 1 : padding
        }
      })
      setScale( () => {
        if (window.innerWidth < 768) {
          return 1
        }
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
          return .5
        } else if (document.body.scrollTop < 0 || document.documentElement.scrollTop < 0) {
          return 1
        } else {
          return 1 - ( document.documentElement.scrollTop / 100 * 50 / 100 )
        }
      })
      setShadow( () => {
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
          return true
        } else {
          return false
        }
      })
    }
    
    window.addEventListener("scroll", handler)
    return () => window.removeEventListener("scroll", handler)
  }, [] );


  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          # filter: {frontmatter: {parent_page: {eq: null}}}
          sort: { fields: [frontmatter___parent_page, frontmatter___sort_order] }
        ) {
          totalCount
          edges {
            node {
              id
              frontmatter {
                page_id
                title
                sort_order
                parent_page
              }
              fields {
                slug
              }
              excerpt
            }
          }
        }
      }
    `
  )

  return (
    <div className="flex flex-col">
      <div className={ classNames("bg-white z-10 fixed w-full justify-self-start h-20 md:h-40", {"shadow-lg": shadow} ) } style={{ paddingTop: `${ padding }rem`, transform: `scaleY(${ scale })` , transformOrigin: `center top` }} />
      <header className="px-2 sm:px-4 md:px-12 container mx-auto z-10 fixed justify-self-start self-center h-20 md:h-40" style={{ paddingTop: `${ padding }rem` }}>
        <Navigation data={ data } scale={ scale } />
      </header>
      <div className="pt-24 md:pt-44">
        <main className="md:px-12 md:container md:mx-auto">
          {children}
        </main>
        <footer className="pt-6 md:pt-12">
          <Footer data={ data } />
        </footer>
      </div>
    </div>
  )
}
