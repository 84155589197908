import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import MenuItems from "./menu_items"

export default function Footer({ data }) {

  return (
    <div className="divide-y divide-white text-gray-400 bg-gray-900 p-12 text-sm md:text-base">
      <MenuItems data={data} />
      <div className="py-6">
        <Link to="/"><StaticImage src="../media/Turbobla_tilleggslogo_hvit.png" width="150" alt="Turbobla" /></Link>      
      </div>
    </div>
  )
}

