import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"

const ListLink = props => (
  <Link to={props.to} className="block mt-4 text-gray-400 hover:text-white mr-10">{props.children}</Link>
)

export default function MenuItems({ data , className }) {

  return (
    <div className={ classNames( "md:flex md:flex-wrap pb-6" , className ) } >
      <div className="mr-6 xl:mr-20 font-medium">
        <ListLink to="/">Hjem</ListLink>
      </div>
      {
        data.allMarkdownRemark.edges.filter( ({ node }) => node.frontmatter.parent_page === null ).map( ({ node }) => 
          <div className="mr-6 xl:mr-20 font-medium">
            <ListLink key={ node.id } to={ node.fields.slug }>{ node.frontmatter.title }</ListLink>
            {
              data.allMarkdownRemark.edges.filter( ({ node: child_node }) => child_node.frontmatter.parent_page === node.frontmatter.page_id ).map( ({ node: child_node }) =>
                <Link key={ child_node.id } to={ child_node.fields.slug } className="block hover:text-white font-light">
                  { child_node.frontmatter.title }
                </Link>
              )
            }
          </div>
        )
      }
      <div className="font-medium">
        <ListLink to="/contact">Kontakt oss</ListLink>
      </div>
    </div>
  )
}
