import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Logo from "../assets/Turbobla_hovedlogo.svg"

import Menu from "./menu"
import Contact from "./contact"

export default function Navigation({ data , scale=1 }) {

  // Gatsby Build won't work unless we test if window is defined (which it is not not the server render)
  const menuPadding = (typeof window !== `undefined` && window.innerWidth < 768) ? 0 : 2*scale-.5

  return (
    <nav className="relative flex justify-between font-menu">
      <div className="absolute flex inset-0 items-start justify-center z-10">
        <Link to="/">
          <div className="w-40 md:w-80 xl:w-112" style={{transform: `scale(${ scale })` , transformOrigin: `center top` }}><Logo /></div>
        </Link>
      </div>
      <Menu data={data} style={{ paddingTop: `${menuPadding}rem`}} />
      <Contact style={{ paddingTop: `${menuPadding}rem`}}/>
    </nav>
  )
}
